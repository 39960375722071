var render, staticRenderFns
import script from "./mife.js?vue&type=script&lang=js&external"
export * from "./mife.js?vue&type=script&lang=js&external"
import style0 from "./mife.scss?vue&type=style&index=0&id=4073a9bc&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4073a9bc",
  null
  
)

export default component.exports